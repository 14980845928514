import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Link } from 'gatsby';

import '../icons-bundle';
import Slider from './slider';
import FullImage from './full-image';
import '../style/style.scss';

const cookieName = 'acceptedCookies';

const Layout = ({ children, slider, hasDots, sliderText, image, page }) => {
	const className = 'page-' + page;

	const [showNotice, setShowNotice] = useState(false);
	useEffect(() => {
		let accepted = false;
		try {
			if (localStorage.getItem(cookieName)) {
				accepted = true;
			}
		} catch (err) {}
		try {
			if (sessionStorage.getItem(cookieName)) {
				accepted = true;
			}
		} catch (err) {}
		if (showNotice === accepted) {
			setShowNotice(!accepted);
		}
	});

	const hideNotice = () => {
		try {
			localStorage.setItem(cookieName, '1');
		} catch (err) {
			try {
				sessionStorage.setItem(cookieName, '1');
			} catch (err) {}
		}
		setShowNotice(false);
	};

	return (
		<>
			<header>
				{slider ? (
					<Slider
						slider={slider}
						hasDots={hasDots}
						sliderText={sliderText}
					/>
				) : image ? (
					<FullImage image={image} />
				) : null}
			</header>
			<main className={className}>{children}</main>
			{showNotice && (
				<div className="cookies">
					<p>
						Wir verwenden technisch notwendige Cookies auf unserer
						Webseite sowie externe Dienste. Standardmäßig sind alle
						externen Dienste deaktiviert. Sie können diese jedoch
						nach belieben aktivieren &amp; deaktivieren. Für weitere
						Informationen{' '}
						<Link to="/datenschutz/">
							lesen Sie unsere Datenschutzbestimmungen
						</Link>
						.
					</p>
					<button onClick={() => hideNotice()}>OK!</button>
				</div>
			)}
			<footer className={className}>
				<div className="page-width">
					<ul className="left">
						<li className="with-logo">
							<Icon icon="vb:footer-logo2" height="auto" />
							Viktoria Burkhardt
							<br />
							Grafikdesign
						</li>
						{/* <li>
							Haldystraße 1 b<br />
							66123 Saarbrücken
						</li> */}
						<li>
							T 0176 63 14 34 61
							<br />
							vb@vb-grafikdesign.de
						</li>
					</ul>
					<ul className="right">
						<li>
							<Link to="/impressum/">Impressum</Link>
						</li>
						<li>
							<Link to="/datenschutz/">Datenschutz</Link>
						</li>
					</ul>
				</div>
			</footer>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	slider: PropTypes.string,
	hasDots: PropTypes.bool,
	sliderText: PropTypes.string,
	image: PropTypes.string,
	page: PropTypes.string,
};

Layout.defaultProps = {
	slider: '',
	hasDots: false,
	sliderText: '',
	image: '',
	page: 'default',
};

export default Layout;
