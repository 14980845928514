// import { Link } from 'gatsby';
// import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import startSlider1 from '../images/start-slider/frame1.jpg';
// import startSlider1x2 from '../images/start-slider/frame1@2x.jpg';
import startSlider2 from '../images/start-slider/frame2.jpg';
// import startSlider2x2 from '../images/start-slider/frame2@2x.jpg';
import startSlider3 from '../images/start-slider/frame3.jpg';
// import startSlider3x2 from '../images/start-slider/frame3@2x.jpg';

const sliders = {
	start: [
		[startSlider1], //, startSlider1x2],
		[startSlider2], //, startSlider2x2],
		[startSlider3], //, startSlider3x2],
	],
};

/**
 * Slider component
 */
const Slider = ({ slider, hasDots, sliderText }) => {
	/*
query ProjectQuery {
  allFile(filter: {extension: {eq: "jpg"}}) {
    nodes {
      name
      ext
      publicURL
      relativePath
      absolutePath
      dir
    }
  }
}

query ProjectQuery {
  allFile(filter: {extension: {eq: "jpg"}}) {
    edges {
      node {
        name
      ext
      publicURL
      relativePath
      absolutePath
      dir
      }
    }
  }
}
	*/

	const items = sliders[slider];
	if (items === void 0) {
		throw new Error(`Missing slider for ${slider}`);
	}

	const containerClass = 'slider slider--' + items.length;
	const dots = [];
	const components = items.map((item, index) => {
		// Add dot
		if (hasDots) {
			dots.push(<span key={index} />);
		}

		// Add image
		const src = item instanceof Array ? item[0] : item;
		if (!item instanceof Array || item.length < 2) {
			return <img key={index} alt="" src={src} />;
		}

		const src2x = item[1];
		return (
			<img
				key={index}
				alt=""
				src={src}
				srcSet={src + ', ' + src2x + ' 2x'}
			/>
		);
	});

	return (
		<div className={containerClass}>
			{components}
			{hasDots || sliderText ? (
				<div className="slider-content">
					<div className="page-width">
						{hasDots ? (
							<div className="slider-dots">{dots}</div>
						) : null}
						{sliderText ? (
							<div className="slider-text">{sliderText}</div>
						) : null}
					</div>
				</div>
			) : null}
		</div>
	);
};

Slider.propTypes = {
	slider: PropTypes.string,
	hasDots: PropTypes.bool,
	sliderText: PropTypes.string,
};

export default Slider;
