import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

const FullImage = ({ image }) => {
	const data = useStaticQuery(graphql`
		query {
			allFile(
				filter: {
					extension: { regex: "/^(jpg|png)$/" }
					dir: { regex: "/full$/" }
				}
			) {
				edges {
					node {
						name
						childImageSharp {
							fluid(maxWidth: 1600) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	`);

	const item = data?.allFile?.edges?.find((item) => item.node.name === image);
	if (!item) {
		return null;
	}

	return <Img fluid={item.node.childImageSharp.fluid} />;
};

export default FullImage;
