/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	const defaultTitle = site.siteMetadata?.title;

	const compiledTitle = title ? title + ' | ' + defaultTitle : defaultTitle;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={compiledTitle}
			meta={[
				metaDescription
					? {
							name: `description`,
							content: metaDescription,
					  }
					: void 0,
				{
					property: `og:title`,
					content: compiledTitle,
				},
				metaDescription
					? {
							property: `og:description`,
							content: metaDescription,
					  }
					: void 0,
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				site.siteMetadata?.author
					? {
							name: `twitter:creator`,
							content: site.siteMetadata?.author || ``,
					  }
					: void 0,
				{
					name: `twitter:title`,
					content: compiledTitle,
				},
				metaDescription
					? {
							name: `twitter:description`,
							content: metaDescription,
					  }
					: void 0,
			]
				.filter((item) => item !== void 0)
				.concat(meta)}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};

export default SEO;
