import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const menu = {
	start: {
		to: '/',
		text: 'Start',
	},
	leistungen: 'Leistungen',
	projekte: {
		to: '/#projekte',
		text: 'Projekte',
	},
	atelier: 'Atelier',
	kontakt: 'Kontakt',
};

const Navigation = ({ selected }) => {
	return (
		<nav>
			<div className="page-width align-center">
				{Object.keys(menu).map((key) => {
					const item = menu[key];
					let text, target;
					if (typeof item === 'string') {
						text = item;
						target = '/' + key + '/';
					} else {
						text = item.text;
						target = item.to;
					}
					const className = selected === key ? 'selected' : '';
					return (
						<Link key={key} to={target} className={className}>
							{text}
						</Link>
					);
				})}
			</div>
		</nav>
	);
};

Navigation.propTypes = {
	selected: PropTypes.string,
};

Navigation.defaultProps = {
	selected: '',
};

export default Navigation;
